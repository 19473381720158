<template>
    <div :class="cn(alertVariants({ variant }), props.class)" role="alert">
        <Icon :name="iconName" class="absolute text-xl top-4 left-4" />
        <slot />
    </div>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { alertVariants, type AlertVariants } from "~/components/ui";
import type { IconName } from "../Icon.vue";

const props = defineProps<{
    class?: HTMLAttributes["class"];
    variant?: AlertVariants["variant"];
}>();

const variantIcons: Record<string, IconName> = {
    default: "info-circle",
    danger: "exclamation-triangle",
};

const iconName = computed<IconName>(() =>
    props.variant ? (variantIcons[props.variant] ?? variantIcons.default) : variantIcons.default
);
</script>
